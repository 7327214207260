<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-start pa-5>
              <v-flex xs12>
                <span class="heading">Edit User</span>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <span class="subheading">Basic Info</span>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Username</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="username"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>Name</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="name"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-2>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Email</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="email"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>Phone No</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="phone"
                      type="number"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-2>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Organization</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="organization"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>Is Rescuer?</span>
                    <v-select
                      :items="isrescueritems"
                      outlined
                      dense
                      hide-details="true"
                      v-model="is_rescuer"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-2>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Is Snake Expert?</span>
                    <v-select
                      :items="issnakeexpertitems"
                      outlined
                      dense
                      hide-details="true"
                      v-model="issnakeexpert"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>Is Rescuer Available?</span>
                    <v-select
                      :items="isrescueravailableitems"
                      outlined
                      dense
                      hide-details="true"
                      v-model="is_rescuer_Available"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-2>
                  <v-flex xs12 sm6 md6 lg6>
                    <v-btn depressed color="primary" @click="handlesubmit()">
                      <span>Update User Details</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center pt-5>
                  <v-flex xs12>
                    <v-layout wrap justify-start>
                      <v-flex xs12>
                        <span class="heading">Change Password</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap justify-start>
                      <v-flex xs12>
                        <span class="subheading">Change Password</span>
                        <v-text-field
                          outlined
                          dense
                          hide-details="true"
                          v-model="password"
                        >
                        </v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-start pt-2>
                      <v-flex xs12 sm6 md6 lg6>
                        <v-btn
                          depressed
                          color="primary"
                          @click="updatepassword()"
                        >
                          <span>Update Password</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      issnakeexpertitems: ["Yes", "No"],
      isrescueravailableitems: ["Yes", "No"],
      isrescueritems: ["Yes", "No"],
      username: "",
      name: "",
      phone: "",
      email: "",
      error: "",
      organization: "",
      is_rescuer: "",
      issnakeexpert: "",
      is_rescuer_Available: "",
      password: "",
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    openEditDialog(item) {
      this.$router.push({
        path: "/edituser",
        query: {
          id: item._id,
        },
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.username = response.data.data.username;
              this.name = response.data.data.name;
              this.email = response.data.data.email;
              this.phone = response.data.data.phone;
              this.organization = response.data.data.organization;
              this.is_rescuer = response.data.data.is_rescuer;
              this.issnakeexpert = response.data.data.issnakeexpert;
              this.is_rescuer_Available =
                response.data.data.is_rescuer_Available;
              if (this.is_rescuer === true) {
                this.is_rescuer = "Yes";
              }
              if (this.is_rescuer === false) {
                this.is_rescuer = "No";
              }
              if (this.is_rescuer_Available === true) {
                this.is_rescuer_Available = "Yes";
              }
              if (this.is_rescuer_Available === false) {
                this.is_rescuer_Available = "No";
              }
              if (this.issnakeexpert === true) {
                this.issnakeexpert = "Yes";
              }
              if (this.issnakeexpert === false) {
                this.issnakeexpert = "No";
              }
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    handlesubmit() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.email)) {
        this.msg = "Please enter a valid email address.";
        this.showSnackBar = true;
        return;
      }
      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(this.phone)) {
        this.msg = "Please enter a valid 10-digit phone number.";
        this.showSnackBar = true;
        return;
      }

      if (this.is_rescuer === "Yes") {
        this.isrescueredit = true;
      }
      if (this.is_rescuer === "No") {
        this.isrescueredit = false;
      }
      if (this.is_rescuer_Available === "Yes") {
        this.isrescuerAvailableedit = true;
      }
      if (this.is_rescuer_Available === "No") {
        this.isrescuerAvailableedit = false;
      }
      if (this.issnakeexpert === "Yes") {
        this.issnakeexpertedit = true;
      }
      if (this.issnakeexpert === "No") {
        this.issnakeexpertedit = false;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          name: this.name,
          username: this.username,
          email: this.email,
          phone: this.phone,
          organization: this.organization,
          is_rescuer: this.isrescueredit,
          is_rescuer_Available: this.isrescuerAvailableedit,
          issnakeexpert: this.issnakeexpertedit,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    updatepassword() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/editpassword",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          password: this.password,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Password Updated Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
<style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
</style>